import * as React from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SetupSuccessSubnav from '../../components/setup_success_subnav'
import { StaticImage } from 'gatsby-plugin-image'
import Container from '../../components/container'
import DemoForm from '../../components/demo_form'

const RoadsterAcademyPage = () => (
  <Layout activeNav={true}>
    <Seo
      title='Roadster Academy'
      excludeTitleSuffix={true}
      description='Ready to revamp your sales game? Roadster Academy will provide you with the product and feature knowledge you need to succeed in automotive sales.'
    />
    <div className='flex flex-col justify-center relative bg-black text-white'>
      <StaticImage
        src='../../images/SS_RoadsterAcademy_Hero.jpg'
        placeholder='blurred'
        alt=''
        className='absolute inset-0'
      />
      <Container className='relative pb-4 pt-5 sm:pb-5 text-white'>
        <h1 className='font-semi-bold text-4vw'>
          Roadster Academy
        </h1>
        <p className='text-md sm:text-lg mb-0' style={{ maxWidth: '42em' }}>
          Become an Express Store expert through our self-guided learning portal.
        </p>
      </Container>
    </div>
    <SetupSuccessSubnav />
    <div className='Section leading-relaxed'>
      <StaticImage 
        src='../../images/HIW_RA_bg.jpg'
        placeholder='blurred'
        alt=''
        quality={100}
        className='absolute inset-0'
      />
      <Container size='md' className='relative'>
        <div className='grid sm:grid-cols-3 sm:gap-5 items-center'>
          <div className='sm:col-span-2'>
            <div className='md:text-md'>
              <h3 className='text-lg md:text-xl font-regular'>
                <img
                  alt=''
                  src='https://media.roadster.com/dealersales/icon-es-certification.svg'
                  className='block mb-em'
                  style={{ width: '2.25em' }}
                />
                Express Storefront® Certification
              </h3>
              <p>
                Becoming a certified Express Storefront expert leads to more personalized experiences and happier customers. The more you know, the better you can navigate through your Express Store with confidence and ease.
              </p>
              <h3 className='text-lg md:text-xl font-regular'>
                <img
                  alt=''
                  src='https://media.roadster.com/dealersales/icon-guided-learning.svg'
                  className='block'
                  style={{ margin: '2.75em 0px 1em', width: '2.875em' }}
                />
                Flexible, Guided Learning
              </h3>
              <p>
                Roadster Academy supports multiple learning formats, with a mix of video and written tutorials. We’re with you every step of the way. It’s interactive learning on your terms.
              </p>
              <h3 className='text-lg md:text-xl font-regular'>
                <img
                  alt=''
                  src='https://media.roadster.com/dealersales/icon-expand-capabilities.svg'
                  className='block'
                  style={{ margin: '2.75em 0px 1em', width: '1.75em' }}
                />
                Expanded Capabilities
              </h3>
              <p>
                Extend your reach and your bottom line. A savvy Express Storefront sales team allows you to serve more customers per day, which translates to greater sales efficiency and increased CSI.
              </p>
            </div>
          </div>
          <div className='sm:col-span-1 hidden sm:block'>
            <StaticImage 
              src='../../images/JosephineScollin.png'
              placeholder='blurred'
              alt=''
              quality={100}
            />
            <div className='text-right leading-tight mt-4' style={{ marginRight: '16%' }}>
              <b>Josephine Scollin</b>
              <br />
              Sr Design Producer
            </div>
          </div>
        </div>
      </Container>
    </div>
    <div className='Section Section--stripe text-md leading-relaxed'>
      <Container size='lg'>
        <div className='text-center'>
          <h2 className='text-xl sm:text-2xl font-light text-center mt-0'>
            Details, Please!
          </h2>
          <p className='mb-4 sm:mb-5'>
            6 features your team will love
          </p>
        </div>
        <div className='grid sm:grid-cols-2 md:grid-cols-3 sm:gap-4'>
          <div>
            <div className='CircleNum'>
              1
            </div>
            <h3 className='mt-3 mb-2'>
              Flexible Course Format
            </h3>
            <p>
              You’re a busy person. We get it. Start and stop anywhere and pick up where you left off, any time.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              2
            </div>
            <h3 className='mt-3 mb-2'>
              Mobile Ready
            </h3>
            <p>
              Enjoy easy, on-the-go access to all learning materials when it’s most convenient for you and your team.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              3
            </div>
            <h3 className='mt-3 mb-2'>
              Full Resource Library
            </h3>
            <p>
              Optimize your Express Store where you need it most. Take unique courses tailored to your role, and download reference content such as best practices and marketing strategies.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              4
            </div>
            <h3 className='mt-3 mb-2'>
              Quick Quizzes
            </h3>
            <p>
              Put your knowledge to the test with quick comprehension check-ins.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              5
            </div>
            <h3 className='mt-3 mb-2'>
              Available 24/7
            </h3>
            <p>
              Up late? Keep on training even after the dealership lights go out.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              6
            </div>
            <h3 className='mt-3 mb-2'>
              All Inclusive
            </h3>
            <p>
              Seriously, it’s already included in your Express Storefront subscription. We don’t joke about helping you perform at your best.
            </p>
          </div>
        </div>
        <h2 className='text-xl sm:text-2xl font-light text-center mt-0 mt-5 mb-4'>
          By The Numbers
        </h2>
        <div className='grid sm:grid-cols-2 md:grid-cols-3 sm:gap-4'>
          <div>
            <img alt='' height='57' src='https://media.roadster.com/dealersales/RA_Offerings_Icon.svg' /> 
            <h3 className='uppercase'>Offerings</h3>
            <ul className='list-reset leading-tight'>
              <li className='mt-2'><span className='font-semi-bold text-silver text-3xl'>90+</span>
                Courses
              </li>
              <li className='mt-2'><span className='font-semi-bold text-silver text-3xl'>10+</span>
                Programs
              </li>
              <li className='mt-2'><span className='font-semi-bold text-silver text-3xl'>5</span>
                Live Trainings
              </li>
            </ul>
          </div>
          <div>
            <img alt='' height='57' src='https://media.roadster.com/dealersales/RA_Engagement_Icon.svg' /> 
            <h3 className='uppercase'>Engagement</h3>
            <ul className='list-reset leading-tight'>
              <li className='mt-2'>
                <div className='font-semi-bold text-silver text-3xl'>21,000+</div>
                Learners
              </li>
              <li className='mt-2'>
                <div className='font-semi-bold text-silver text-3xl'>300,000+</div>
                Completed Enrollments
              </li>
              <li className='mt-2'>
                <div className='font-semi-bold text-silver text-3xl'>17,000+</div>
                Hours Spent on Learning
              </li>
              <li className='mt-2'>
                <div className='font-semi-bold text-silver text-3xl'>12,000+</div>
                Completed Certifications
              </li>
            </ul>
          </div>
          <div>
            <img alt='' height='57' src='https://media.roadster.com/dealersales/Certifiedagents.svg' /> 
            <h3 className='uppercase'>Certified Agents</h3>
            <div className='mt-2 Grid Grid--gutter2 items-center'>
              <div className='size-fit'>
                <div className='font-semi-bold flex items-center justify-center bg-black rounded-full text-white text-xl' style={{ width: '2.5em', height: '2.5em' }}>
                  11x
                </div>
              </div>
              <div className='flex-1'>
                “share” vehicle details with customers over 11x more than non-certified agents
              </div>
            </div>
            <div className='mt-3 Grid Grid--gutter2 items-center'>
              <div className='size-fit'>
                <div className='font-semi-bold flex items-center justify-center bg-black rounded-full text-white text-xl' style={{ width: '2.5em', height: '2.5em' }}>
                  8x
                </div>
              </div>
              <div className='flex-1'>
                “complete” vehicle orders over 8x more than non-certified agents
              </div>
            </div>
            <div className='mt-3 Grid Grid--gutter2 items-center'>
              <div className='size-fit'>
                <div className='font-semi-bold flex items-center justify-center bg-black rounded-full text-white text-xl' style={{ width: '2.5em', height: '2.5em' }}>
                  10x
                </div>
              </div>
              <div className='flex-1'>
                start over 10x more orders than non-certified agents
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className='flex justify-center mt-4'>
        <StaticImage 
          src='../../images/RA_Desktop.png'
          placeholder='blurred'
          alt=''
          width={882}
        />
      </div>
    </div>
    <div className='Section Section--dark'>
      <StaticImage 
        src='../../images/honda_testimonial_bg.jpg'
        placeholder='blurred'
        alt=''
        quality={100}
        className='absolute inset-0'
      />
      <div className='bg-darken5 absolute inset-0'></div>
      <Container size='sm' className='relative text-white text-center'>
        <div className='font-serif font-semi-bold italic leading-none text-gray' style={{ fontSize: '12rem' }}>
          ”
        </div>
        <div className='Testimonial'>
          With Roadster Academy, everyone understood how to use the Express Storefront even before training began. We perfected what we learned during training, and in just 19 days, we had 231 in-store shares and 17 orders.
        </div>
        <div className='sm:text-md mt-3'>
          <div>
            Peter Larson
          </div>
          <div className='font-semi-bold'>
            General Sales Manager, Manchester Honda
          </div>
        </div>
      </Container>
    </div>
    <div className='Section'>
      <Container>
        <DemoForm />
      </Container>
    </div>
  </Layout>
)

export default RoadsterAcademyPage
